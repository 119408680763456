<script>
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

	export let buttonRef = null;
	export let id = undefined;
	export let type = 'button';
	export let imitateTopLabel = false;
	export let disabled = false;
	export let tabindex = undefined;
	export let baseClass = 'py-2 border border-transparent text-base font-medium rounded-md shadow-sm';

	const handleClick = () => dispatch('click', { id });
</script>

{#if imitateTopLabel}
	<span class="block text-sm font-medium mb-1">&nbsp;</span>
{/if}

<button
	{id}
	{type}
	class="{baseClass} {$$props.class} {disabled ? 'opacity-50 cursor-not-allowed' : ''}"
	style={$$props.style}
	{disabled}
	{tabindex}
	on:click={handleClick}
	on:focus={(e) => dispatch('focus', { id, event: e })}
	on:blur={(e) => dispatch('blur', { id, event: e })}
	bind:this={buttonRef}
>
	<slot />
</button>
